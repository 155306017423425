.coming-soon-wrapper {
  background: #f5f5f5;
  padding: 44px 62px;
  @media (max-width: 1024px) {
    padding: 44px;
  }
  @media (max-width: 1024px) {
    padding: 22px;
  }
  .coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      text-transform: uppercase;
      font-size: 32px;
      letter-spacing: -0.06em;

      @media (max-width: 768px) {
        font-size: 26px;
        padding: 32px 0;
      }
    }
    img {
      max-width: 206px;
      width: 100%;
    }
  }
  .comingSoon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    .coming-soon-para {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.63) 50.28%,
        rgba(0, 0, 0, 0.9) 100%
      );
      padding: 54px 52px;
      width: 300px;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      h5 {
        color: #ffffff;
        font-size: 20px;
        text-transform: uppercase;
        line-height: 28px;
        text-align: center;
        margin-top: 16px;
        @media (max-width: 768px) {
          font-size: 18px;
        }
        @media (max-width: 425px) {
          font-size: 12px;
        }
      }
    }
  }
}
