.auth-layout-wrapper {
  .auth-layout-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
      display: block;
    }

    .auth-layout-content {
      padding: 57px 94px;
      overflow-y: hidden;
      height: 100vh;
      @media (max-width: 1024px) {
        padding: 55px;
      }
      @media (max-width: 768px) {
        display: none;
      }

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 200px;
          width: 100%;
        }
      }
      .printing-img {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          max-width: 480px;
          width: 100%;
        }
      }
      p {
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
