@font-face {
  font-family: "Gilroy-Bold";
  src: url("../src/assets/fonts/Gilroy-Bold.ttf");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("../src/assets/fonts/Gilroy-Medium.ttf");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../src/assets/fonts/Gilroy-SemiBold.ttf");
}
@font-face {
  font-family: "Gilroy-Light";
  src: url("../src/assets/fonts/Gilroy-Light.ttf");
}

:root {
  --font-bold: "Gilroy-Bold";
  --font-medium: "Gilroy-Medium";
  --font-semiBold: "Gilroy-SemiBold";
  --font-light: "Gilroy-Light";
  --white: #fff;
  --primary-gray: #9b9899;
  --secondary-gray: #aaaaaa;
  --text-primary: #373435;
  --text-secondary: #9b9899;
  --yellow: #eec900;
  --gray: #777777;
  --border: #dddddd;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-medium);
}
html {
  scroll-behavior: smooth;
}
body {
  color: var(--text-primary);
  transition: all 0.3s linear;
}

.mx {
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: var(--text-primary);
}
button:disabled {
  cursor: no-drop;
}

.flex-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
p {
  font-size: 14px;
  color: var(--text-primary);
}
@media (max-width: 780px) {
  p {
    font-size: 12px;
  }
}
h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: var(--text-primary);
  letter-spacing: -0.06em;
}
h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: var(--text-primary);
  letter-spacing: -0.06em;
}
h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-primary);
}
h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.06em;
  color: var(--text-primary);
}

span {
  color: var(--yellow);
  @media (max-width: 480px) {
    font-size: 10px;
  }
}
.errorMsg {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 12px;
  text-transform: capitalize;
}
label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.06em;
  color: var(--text-primary);
  text-transform: uppercase;
}
select {
  outline: none;
  border: 1px solid var(--border);
}
option {
  box-shadow: none;
  padding: 12px;
}
.coming-soon-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-file-upload {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px 32px;
  cursor: pointer;
  background-color: var(--text-primary);
  color: #ffffff;
  margin-bottom: 5px;
  @media (max-width: 480px) {
    padding: 12px 16px;
  }
}

.custom-file-upload input[type="file"] {
  display: none;
}

.drop-down-icon.rotate {
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}

button.slick-arrow.slick-next::before {
  display: none;
}
button.slick-arrow.slick-prev::before {
  display: none;
}

input[type="checkbox"] {
  accent-color: var(--yellow);
}
#number::-webkit-inner-spin-button,
#number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.payment-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.payment-content img {
  max-width: 400px;
  width: 100%;
}
.payment-content h2 {
  text-align: center;
  font-size: 36px;
}
@media (max-width: 768px) {
  .payment-content h2 {
    font-size: 26px;
  }
}

@media (max-width: 420px) {
  .payment-content h2 {
    font-size: 22px;
  }
}
