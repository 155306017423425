.footer-wrapper {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 32px;
  padding: 70px 64px;
  @media (max-width: 1024px) {
    padding: 52px 42px;
  }
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 425px) {
    padding: 50px 32px;
  }
  .footer-first-content {
    .logo {
      margin-bottom: 24px;
      img {
        width: 352px;
        @media (max-width: 768px) {
          width: 250px;
        }
      }
    }
    p {
      font-size: 14px;
      color: var(--text-secondary);
      line-height: 22px;
    }
    .social-media {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-top: 26px;
      color: #000;
    }
  }
  .footer-second-content {
    @media (max-width: 768px) {
      margin-top: 32px;
    }
    .contact-content {
      p {
        font-size: 20px;
        margin-bottom: 16px;
      }
      .contact {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          width: 16px;
          margin-top: -12px;
        }
        p {
          color: var(--text-primary);
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .newsletter {
      margin-top: 32px;
      P {
        font-size: 20px;
        margin-bottom: 16px;
      }
      .search-box {
        display: flex;
        align-items: center;
        input {
          padding: 13px 16px;
          border: 1px solid #dddddd;
          outline: none;
          width: 100%;
          @media (max-width: 425px) {
            padding: 13px 18px;
          }
        }
        button {
          padding: 12px 24px;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;

          svg {
            margin-top: 0.6px;
          }
          @media (max-width: 425px) {
            padding: 12px 18px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
