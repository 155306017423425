.loderbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid (var(--yellow));
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
  }
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
