.header {
  @media (max-width: 992px) {
    display: none;
  }
}
.layoutWrapper {
  .sidebar-wrapper {
    max-width: 214px;
    width: 100%;
    border-right: 1px solid #dddddd;
    padding: 32px 14px;
    height: calc(100vh - 68px);
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 68px;
    left: 0;
    z-index: 1;
    @media (max-width: 992px) {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    .step-section {
      display: flex;
      gap: 11px;
      h2 {
        margin-top: -10px;
      }
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          width: 16px;
          height: 16px;
          // background: var(--yellow);
          border: 1px solid #373435;
          border-radius: 30px;
        }
      }
    }
    .printing-Img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      img {
        max-width: 214px;
      }
    }
  }
  .layout-children {
    overflow-y: auto;
    height: 100%;
    flex: 1;
    margin-left: 214px;
    margin-top: 68px;

    @media (max-width: 992px) {
      margin-top: 0;
      margin-left: 0;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}
