.CompanyDetailForm {
  padding: 32px 93px;
  @media (max-width: 780px) {
    padding: 36px;
  }
  h2 {
    padding-bottom: 32px;
  }
  .submit-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin: 32px 0;
    .upload-btn {
      background-color: #373435;
      color: #ffffff;
      padding: 12px 0;
      cursor: pointer;
    }
    button {
      width: 100%;
    }
    @media (max-width: 780px) {
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
}
.create-file-wrapper {
  @media (min-width: 992px) {
    display: none;
  }
}

//Company-detail
.company-details {
  .company-logo {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 16px 36px 16px 16px;
    margin-top: 8px;
    margin-bottom: 24px;
    border: 3px dashed #dddddd;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: normal;
    }
  }
  .upload-logo {
    display: flex;
    align-items: center;
    gap: 16px;
    .file-valaidation {
      .file-limit {
        p {
          color: #fa9e57;
          padding-top: 4px;
        }
      }
    }
  }
  .register-detials {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6px;
    @media (max-width: 780px) {
      display: block;
    }
    .input-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .company-select {
    select {
      width: 100%;
      padding: 12px;
      margin-top: 8px;
      @media (max-width: 780px) {
        margin-bottom: 16px;
      }
    }
    .select-option {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      margin-bottom: 24px;
      @media (max-width: 780px) {
        display: block;
      }

      .errormsg {
        color: red;
        font-size: 12px;
        margin-bottom: 12px;
        @media (max-width: 780px) {
          margin-top: -12px;
        }
      }
    }
    .cidb-section {
      border: 1px solid var(--border);
      margin-bottom: 40px;
      margin-top: 8px;

      .cidb-register {
        border-bottom: 1px solid var(--border);
        padding: 14px 16px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.5s;
        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .cidb {
        display: flex;

        justify-content: space-between;
        align-items: center;
        padding: 21px 17px;
        border-bottom: 1px solid var(--border);
        @media (max-width: 780px) {
          // flex-wrap: wrap;
          flex-direction: column;
          align-items: normal;
        }
        .cidb-checked {
          display: flex;
          align-items: center;
          gap: 17px;
        }
        .cidb-upload {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          @media (max-width: 480px) {
            flex-direction: column;
            align-items: normal;
            gap: 10px;
            padding-top: 10px;
          }
          .form-check {
            display: none;
          }

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            border-radius: 30px;
            cursor: pointer;
          }

          input[type="checkbox"]:checked + label::before {
            content: "";
            display: block;
            position: absolute;
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 30px;
            justify-content: space-evenly;
            background-color: #ebebeb;
            background-color: #fa9e57;
            font-family: "Montserrat";
            border-radius: 2px;
            border: 1px solid rgb(150 150 150 / 30%);
            cursor: pointer;
          }

          .circle {
            display: flex;
            align-items: center;
            gap: 16px;
            @media (max-width: 780px) {
              gap: 6px;
              justify-content: space-around;
            }
            .circleleavel {
              // background: var(--yellow);
              width: 20px;
              height: 20px;
              border-radius: 30px;
              color: #9b9899;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: 0.5s;
              padding: 6px;
              font-size: 10px;
              background: #ebebeb;
              @media (max-width: 480px) {
                width: 16px;
                height: 16px;
              }
            }

            .circleselect {
              display: flex;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 30px;
              justify-content: space-evenly;
              background-color: #ebebeb;
              color: #fff;
              cursor: pointer;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
}

//companycontactdetials

.company-contact-detials {
  .contact-detials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 8px;

    .contact-input {
      width: 100%;
      border-radius: 0%;
      padding: 21px 46px;
      @media (max-width: 480px) {
        padding: 12px 0 12px 40px;
      }
    }
    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      align-items: normal;
    }
  }
}
//uplodableDocuments
.documents-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 28px 16px 16px;
  margin-top: 8px;
  margin-bottom: 24px;
  border: 3px dashed #dddddd;
  @media (max-width: 780px) {
    border: 4px dashed var(--border);
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: normal;
    gap: 10px;
  }

  .pdf-logo {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 440px) {
      padding-bottom: 8px;
    }
  }
}

//ceodetials

.ceo-detials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ceo-input {
    input {
      width: 428px;
      @media screen {
        width: 300px;
      }
    }
  }
}

//DirectorsDetails

.director-detials {
  .director-info {
    border: 1px solid #dddddd;

    .number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;
      border-bottom: 1px solid #dddddd;
    }
  }
  .nameSection {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    border-bottom: 1px solid #dddddd;
    @media (max-width: 720px) {
      display: flex;
      flex-direction: column;
      align-items: normal;
    }
    .nameSection-one {
      width: 322px;
      @media (max-width: 980px) {
        // max-width: 200px;
        width: 100%;
      }
      @media (max-width: 780px) {
        // max-width: 150px;
        width: 100%;
      }
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #373435;
    }
    .remove {
      width: 14px;
      height: 2px;
      background: #373435;
    }
    .upload-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
}
.display-img {
  @media (max-width: 780px) {
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
}
.minus {
  cursor: pointer;
}
