.header-wrapper {
  border-bottom: 1px solid #dddddd;
  padding: 12px 24px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  .mx {
    position: relative;
    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo-img {
        img {
          max-width: 220px;
          width: 100%;
          object-fit: contain;
        }
      }

      .profiles-content {
        gap: 27px;

        .profile {
          background-color: var(--yellow);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 18px;
            font-weight: 300;
            color: #fff;
          }
          cursor: pointer;
        }
        .bell {
          cursor: pointer;
        }
      }
    }
    .logout-content {
      position: absolute;
      top: 0px;
      right: 0;
      background: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 16px;
      z-index: -1;
      max-width: 252px;
      width: 100%;

      .profile {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18px;
          font-weight: 300;
          color: #fff;
          background-color: var(--yellow);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        cursor: pointer;
      }
      h6 {
        margin-top: 16px;
        font-size: 14px;
        text-align: center;
      }
      p {
        font-size: 12px;
        color: var(--primary-gray);
        margin-top: 4px;
        text-align: center;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        padding: 8px 32px;
        width: 100%;
        margin-top: 16px;
        background: #ee5050;
        svg {
          margin-top: 3px;
        }
      }
    }
  }
}
