.walmark-img {
  img {
    object-fit: cover;
    width: 100%;
    height: 300px;
    @media (max-width: 768px) {
      height: 170px;
    }
  }
}
.walmark-pvt-ltd-content {
  margin-top: -4px;
  background: #f5f5f5;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 8px 16px;
  }
  .walmark-para {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 80px;
      @media (max-width: 768px) {
        width: 34px;
        height: 34px;
      }
    }
    h6 {
      font-size: 20px;
      line-height: 24px;
      font-family: var(--font-semiBold);
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    p {
      color: #9b9899;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  button {
    padding: 12px 24px;
    @media (max-width: 768px) {
      padding: 8px 16px;
    }
  }
}
.files-card {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  gap: 16px;
  margin-bottom: 118px;
  .card {
    border: 1px solid #dddddd;
    .files-img {
      background: #f5f5f5;

      img {
        max-width: 200px;
        width: 100%;
      }
    }
    .files-end-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 21px 12px;
      .pdf-file {
        display: flex;
        align-items: center;
        gap: 8px;
        .content {
          h6 {
            font-size: 14px;
            margin-bottom: 4px;
          }
          p {
            color: var(--primary-gray);
            font-size: 12px;
          }
        }
        img {
          width: 30px;
          height: 30px;
        }
      }
      .new {
        background: rgba(238, 201, 0, 0.2);
        padding: 4px 8px;
        p {
          font-size: 12px;
          color: var(--yellow);
        }
      }
    }
  }
}
