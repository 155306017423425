.no-recentfile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (max-width: 768px) {
    display: block;
  }
  h4 {
    font-size: 40px;
    letter-spacing: -0.06em;
    @media (max-width: 768px) {
      text-align: center;
      margin-top: 48px;
      font-size: 32px;
    }
    @media (max-width: 425px) {
      font-size: 28px;
    }
  }
  .no-recentfile-img {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      max-width: 420px;
      width: 100%;
    }
  }
}
