.safety-file-header {
  @media (max-width: 992px) {
    display: none;
  }
}

.safety-file-wrapper {
  margin-top: 90px;
  padding: 24px 120px;
  @media (max-width: 768px) {
    padding: 24px 32px;
  }
  @media (max-width: 992px) {
    margin-top: 0;
  }
  .safety-file-heading {
    font-family: var(--font-semiBold);
    font-size: 40px;
    letter-spacing: -0.06em;
    margin-bottom: 24px;
    color: var(--text-primary);
    &::before {
      content: "";
    }
    &::after {
      content: ".";
      color: var(--yellow);
    }
    @media (max-width: 525px) {
      font-size: 26px;
    }
  }
  .input-first-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    gap: 8px;
  }
  .table-Wrapper {
    border: 1px solid #dddddd;
    @media (max-width: 768px) {
      border: none;
    }
    .table-head {
      border-bottom: 1px solid #dddddd;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.3fr;
      gap: 8px;
      padding: 24px 16px;
      @media (max-width: 1024px) {
        display: none;
      }
      p {
        color: var(--text-secondary);
      }
    }
    .table-container {
      padding: 0 16px;
      @media (max-width: 768px) {
        padding: 0;
      }
      .table-content {
        margin: 8px 0;
        border-bottom: 1px solid #dddddd;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 0.2fr;
        gap: 16px;
        @media (max-width: 1024px) {
          grid-template-columns: repeat(auto-fill, minmax(276px, 1fr));
        }
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0;
        }
        &:nth-child(6) {
          border-bottom: none;
        }
        .table-flex-content {
          display: flex;
          align-items: center;
        }

        p {
          @media (max-width: 768px) {
            text-transform: uppercase;
            font-size: 12px;
            margin: 8px 0;
          }
          span {
            color: var(--yellow);
          }
        }
        .upload-btn {
          display: flex;
          align-items: center;
          margin-top: -8px;

          button {
            display: flex;
            gap: 8px;
            font-size: 14px;
            padding: 12px 30px;
            @media (max-width: 768px) {
              margin: 8px 0 16px;
            }
          }
          .right-icon-btn {
            button {
              padding: 11px 12px;
            }
          }
        }
      }
    }
  }
  .plant-operator-wrapper {
    border: 1px solid #dddddd;
    margin-top: 24px;
    @media (max-width: 768px) {
      border-top: 1px solid #dddddd;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
    .plant-operator-head {
      border-bottom: 1px solid #dddddd;
      padding: 24px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 24px 0;
      }
      .add-btn {
        button {
          display: flex;
          align-items: center;
          gap: 11px;
          background: #dddddd;
          color: var(--text-primary);
          font-size: 14px;
          svg {
            margin-top: 1.5px;
          }
        }
      }
    }
    .plant-operator-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr 0.3fr;
      gap: 8px;
      padding: 16px;
      border-bottom: 1px solid #dddddd;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0;
        padding: 0;
        margin: 16px 0;
      }

      .upload-content {
        display: flex;
        align-items: center;
        gap: 21px;
        @media (max-width: 480px) {
          justify-content: space-between;
        }
        button {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: -8px;
          @media (max-width: 768px) {
            margin: 4px 0 16px;
          }
        }
        .minus {
          cursor: pointer;
        }
      }
    }
  }
  .safety-file-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 24px 0 64px;
    button {
      width: 100%;
      font-size: 14px;
    }
  }
}
