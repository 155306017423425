.login-container {
  background: linear-gradient(90deg, #f5f5f5 0%, #ffffff 100%);
  overflow-y: scroll;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-gray);
    border-radius: 50px;
  }

  .error-window {
    position: absolute;
    top: 60px;
    right: 0%;
    transform: translate(-50%, -50%);
    background: tomato;
    max-width: 400px;
    width: 100%;
    padding: 12px 32px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    @media (max-width: 1024px) {
      top: 40px;
      left: 73%;
    }
    @media (max-width: 768px) {
      top: 150px;
      left: 49%;
    }
    @media (max-width: 425px) {
      max-width: 270px;
    }
    p {
      color: #fff;
      text-align: center;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: -60px;
    margin-top: 52px;
    img {
      width: 120px;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
  .login-wrapper {
    padding: 191px 96px;
    max-width: 532px;
    width: 100%;
    margin: 0 auto;
    height: 100vh;

    @media (max-width: 1024px) {
      padding: 56px;
    }

    .login-text {
      font-family: var(--font-bold);
      font-size: 40px;
      letter-spacing: -0.06em;
      margin-bottom: 32px;
      color: var(--text-primary);
      &::before {
        content: "";
      }
      &::after {
        content: ".";
        color: var(--yellow);
      }
      @media (max-width: 425px) {
        font-size: 32px;
        text-align: center;
      }
    }
    .forgot-password {
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;
      a {
        color: var(--yellow);
        font-size: 14px;
        margin-top: -8px;
      }
    }
    button {
      width: 100%;
      margin: 24px 0;
    }
    .line-border {
      display: flex;
      align-items: center;
      gap: 12px;
      .border {
        border-top: 1px solid #dddddd;
        width: 170px;
        @media (max-width: 450px) {
          width: 130px;
        }
      }
      p {
        color: var(--gray);
      }
    }
    .google-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: 1px solid #dddddd;
      padding: 12px 0;
      width: 100%;
      margin: 24px 0;
      cursor: pointer;
    }
    .sign-up-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      a {
        color: var(--yellow);
        font-size: 14px;
      }
    }
    .terms-condition {
      display: flex;
      flex-direction: column;
      gap: 18px;
      cursor: pointer;
      .terms {
        display: flex;
        gap: 8px;
        input {
          cursor: pointer;
        }
      }
    }
  }
}
