.payments {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 64px;
  background-color: #eee;
  h4 {
    padding-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.92px;
    @media (max-width: 480px) {
      font-size: 26px;
    }
  }
  .payment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 780px) {
      flex-direction: column-reverse;
    }
    .payment-logo {
      display: flex;
      gap: 49px;
      padding: 24px 0;
      align-items: center;
      @media (max-width: 980px) {
        svg {
          width: 120px;
          height: 40px;
        }
      }

      @media (max-width: 780px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
