.company-head {
  border-bottom: 1px solid #dddddd;
  padding: 16px 24px;
  @media (max-width: 992px) {
    display: none;
  }
}
.add-new-company {
  padding: 16px;
  border-bottom: 1px solid #dddddd;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  gap: 16px;
  .border {
    border: 1px solid #dddddd;
    width: 100%;

    .backgrund-icon {
      background: #f5f5f5;
      height: 196px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add-new-company-para {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px;
      .building-circle {
        background: #f1f1f0;
        border-radius: 40px;
        width: 40px;
        height: 40px;
      }
    }
  }
}
.add-company-container-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  gap: 16px;
  padding: 14px 24px;
  .walmark-img {
    img {
      max-width: 328px;
      width: 100%;
    }
  }
  .company-name-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.add-company-container-view {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  gap: 16px;
  margin-bottom: 118px;
  .card {
    border: 1px solid #dddddd;
    .add-company-content {
      background: #f5f5f5;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
    .files-end-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 12px;
      .pdf-file {
        display: flex;
        align-items: center;
        gap: 8px;
        .logo {
          img {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }
        .content {
          h6 {
            font-size: 14px;
            margin-bottom: 4px;
          }
          p {
            color: var(--primary-gray);
            font-size: 12px;
          }
        }
      }
      .new {
        background: rgba(238, 201, 0, 0.2);
        padding: 4px 8px;
        p {
          font-size: 12px;
          color: var(--yellow);
        }
      }
    }
  }
}
