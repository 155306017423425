.hero-wrapper {
  margin-top: 68px;
  background-image: url("../../assets/images/ubucon-img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: calc(100vh - 68px);
  @media (max-width: 425px) {
    margin-top: 62px;
  }

  .hero-container {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .mobile-responsive-heading {
      h1 {
        font-size: 42px;
        letter-spacing: -0.06em;
        padding: 48px 32px;
        text-align: center;

        @media (min-width: 768px) {
          display: none;
        }
        @media (max-width: 425px) {
          font-size: 32px;
          padding: 34px 16px;
        }
      }
    }

    .hero-content {
      display: grid;
      grid-template-columns: 2fr 1fr;
      place-items: center;

      padding: 32px 97px 32px 0;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0;
      }

      .hero-first-content {
        max-width: 800px;
        width: 100%;
        @media (max-width: 1024px) {
          max-width: 400px;
        }
        @media (max-width: 625px) {
          max-width: 300px;
        }
        .image {
          img {
            max-width: 600px;
            width: 100%;
            @media (max-width: 1024px) {
              max-width: 300px;
            }
            @media (max-width: 625px) {
              max-width: 350px;
            }
          }
        }
        .image-two {
          img {
            max-width: 700px;
            width: 100%;
            @media (max-width: 1024px) {
              max-width: 350px;
            }
            @media (max-width: 625px) {
              max-width: none;
            }
            @media (max-width: 425px) {
              width: 350px;
            }
          }
        }
        .flex-container {
          @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .hero-second-content {
        @media (max-width: 768px) {
          padding: 0 32px;
          text-align: center;
        }
        h1 {
          font-size: 54px;
          line-height: 76px;
          letter-spacing: -0.06em;
          @media (max-width: 1024px) {
            font-size: 42px;
            line-height: 62px;
          }
          @media (max-width: 768px) {
            display: none;
          }
        }
        p {
          font-size: 20px;
          line-height: 32px;
          margin: 24px 0;
          @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 28px;
          }
          @media (max-width: 425px) {
            padding-top: 22px;
            font-size: 16px;
          }
        }
        .flex-btn {
          @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          button {
            @media (max-width: 768px) {
              margin-bottom: 32px;
            }
          }
        }
      }
    }
  }
}
.slick-dots {
  color: #373435 !important;
  right: 90px !important;
  @media (max-width: 780px) {
    right: 0% !important;
  }
  li {
    position: relative;
    display: inline-block;
    width: 4px !important;
    height: 30px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    color: #373435 !important;
  }
}
.slick-dots {
  padding-left: 10px !important;
}
.slick-dots li button:before {
  font-family: "slick" !important;
  font-size: 8px !important;
  line-height: 20px !important  ;
  position: absolute !important;
  left: 0 !important;
  content: "•" !important;
  text-align: center !important;
  color: #373435 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: var(--yellow) !important;
  font-size: 12px !important;
}
