.pricing-plans-wrapper {
  .ubucon-img {
    background-image: url("../../assets/images/ubucon-bg-img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 80vh;
    position: relative;
    .ubucon-container {
      position: absolute;
      top: 32px;
      left: 64px;

      h4 {
        letter-spacing: -0.06em;
        font-size: 32px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .slideshow-wrapper {
      inset: 0;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);

      .slide-card {
        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
          font-size: 36px;
          font-weight: 100;
          line-height: 33px;
          text-align: center;
          padding: 4px 0;
          @media (max-width: 480px) {
            font-size: 24px;
            line-height: 23px;
            padding: 2px 0;
          }
        }
        h5 {
          font-size: 24px;
          font-family: var(--font-bold);
          margin-top: 20px;
          text-align: center;
        }
      }
    }
    .reg-btn {
      z-index: 10;
      position: absolute;
      top: 61%;
      left: 50%;
      transform: translate(-50%, -50%);

      button {
        background: var(--yellow);
        cursor: pointer !important;
      }
    }
    .moblie-head {
      position: absolute;
      top: 79%;
      left: 50%;
      transform: translate(-50%, -50%);
      h4 {
        letter-spacing: -0.06em;
        font-size: 32px;
        text-align: center;
      }
      @media (min-width: 768px) {
        display: none;
      }
    }
    .slide-arrows {
      position: absolute;
      top: 50%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px;
      @media (max-width: 425px) {
        padding: 12px;
      }
      img {
        @media (max-width: 425px) {
          width: 52px;
        }
      }

      .left-arrow {
        all: unset;
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          display: none;
        }
      }

      .right-arrow {
        all: unset;
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          display: none;
        }
      }
    }
  }
}
