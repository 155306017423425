.add-company-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-top: 40px;

  @media (max-width: 768px) {
    display: block;
  }
  h4 {
    font-size: 40px;
    letter-spacing: -0.06em;
    @media (max-width: 768px) {
      text-align: center;
      margin-top: 48px;
      font-size: 32px;
    }
    @media (max-width: 425px) {
      font-size: 28px;
    }
  }
  .add-company-btn {
    margin-top: 16px;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      img {
        width: 18px;
        margin-bottom: -4px;
        margin-right: 4px;
      }
    }
  }
  .add-company-img {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 420px;
      width: 100%;
    }
  }
}
