.input-wrapper {
  .label-content {
    margin-bottom: 8px;
    label {
      letter-spacing: 0.06em;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      color: var(--text-primary);
    }
  }
  .input-content {
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    margin-bottom: 11px;
    width: 100%;
    input {
      padding: 12px 16px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

    input {
      font-size: 14px;
      width: 100%;
      outline: none;
      border: none;
      color: var(--text-primary);
      background: transparent;
    }
    .icon {
      margin-right: 16px;
    }
  }
}
