.sidebar-wrapper {
  max-width: 214px;
  width: 100%;
  border-right: 1px solid #dddddd;
  padding: 24px;
  height: calc(100vh - 68px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 68px;
  left: 0;
  z-index: 1;
  @media (max-width: 992px) {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
  .drop-down {
    .sidebar-flex-container {
      gap: 34px;
      cursor: pointer;
      padding: 12px 16px;
      &:hover {
        background: #f0f0f0;
      }
      .building {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          line-height: 16px;
        }
      }

      .drop-down-icon {
        transition: all 0.5s ease-out;
        &:hover {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .Printing-Img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      max-width: 214px;
      width: 100%;
    }
  }
  .company-name {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 16px;
    height: 350px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 22px;
      background: #dddddd;
    }
    .link-tag {
      margin: 16px 0;
      cursor: pointer;

      p {
        font-size: 14px;
      }
      .link {
        a {
          &:hover {
            color: var(--yellow);
          }
        }
      }
    }
  }
}
