.ubucon-section-wrapper {
  padding: 64px 82px;
  @media (max-width: 768px) {
    padding: 32px;
  }
  @media (max-width: 425px) {
    padding: 32px 24px;
  }
  .ubucon-section-content-left {
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .content {
      .memories {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 0 auto;
        overflow-x: hidden;
        width: 100%;
        max-width: 400px;
        padding: 0 60px 35px 60px;
        @media (max-width: 1050px) {
          margin: 2rem 2rem 0;
          width: 90%;
        }
        @media (max-width: 768px) {
          margin: 2rem 0rem 0;
          width: 100%;
        }
        .memories-card_wrapper {
          position: relative;
          max-width: 400px;
          aspect-ratio: 16/10;
          width: 100%;
          max-width: 400px;
          overflow-x: visible;
          @media (max-width: 950px) {
            max-width: 85vw;
            margin: 0;
          }
          .memories-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 400px;
            aspect-ratio: 16/9;
            border-radius: 10px;
            @media (max-width: 950px) {
              max-width: 90vw;
              margin: 0;
            }
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
              pointer-events: none;
            }
            .flex-content {
              color: #fff;

              svg {
                width: 20px;
                @media (max-width: 420px) {
                  width: 16px;
                }
              }

              .map-pin {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 18px;
                left: 15px;
                @media (max-width: 450px) {
                  gap: 4px;
                }
                @media (max-width: 420px) {
                  bottom: 15px;
                }
                h5 {
                  font-weight: 500;
                  @media (max-width: 585px) {
                    font-size: 14px;
                  }
                  @media (max-width: 500px) {
                    width: 200px;
                  }
                  @media (max-width: 420px) {
                    font-size: 12px;
                    width: 150px;
                  }
                }
              }
              .date-content {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 18px;
                right: 15px;
                @media (max-width: 450px) {
                  gap: 4px;
                }
                h5 {
                  font-weight: 500;
                  @media (max-width: 585px) {
                    font-size: 14px;
                  }

                  @media (max-width: 420px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .img-flex-wrapp {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 56px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
      h4 {
        letter-spacing: -0.06em;
        font-size: 32px;
        margin-bottom: 20px;
      }
      p {
        font-size: 20px;
        line-height: 32px;
        color: var(--gray);
        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
      h5 {
        text-transform: uppercase;
        font-size: 22px;
        margin-top: 16px;
        color: var(--gray);
        line-height: 32px;
        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
    }
    .content-section {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 56px;
      @media (max-width: 780px) {
        flex-direction: column;
      }
    }
  }
  .ubucon-section-content-right {
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .content {
      .title-window {
        display: none;
        @media (max-width: 780px) {
          display: block;
        }
      }
      .memories {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 0 auto;
        overflow-x: hidden;
        width: 100%;
        max-width: 400px;
        padding: 0 60px 35px 60px;
        @media (max-width: 1050px) {
          margin: 2rem 2rem 0;
          width: 90%;
        }
        @media (max-width: 768px) {
          margin: 2rem 0rem 0;
          width: 100%;
        }
        .memories-card_wrapper {
          position: relative;
          max-width: 400px;
          aspect-ratio: 16/10;
          width: 100%;
          max-width: 400px;
          overflow-x: visible;
          @media (max-width: 950px) {
            max-width: 85vw;
            margin: 0;
          }
          .memories-card {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 400px;
            aspect-ratio: 16/9;
            border-radius: 10px;
            @media (max-width: 950px) {
              max-width: 90vw;
              margin: 0;
            }
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
              pointer-events: none;
            }
            .flex-content {
              color: #fff;

              svg {
                width: 20px;
                @media (max-width: 420px) {
                  width: 16px;
                }
              }

              .map-pin {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 18px;
                left: 15px;
                @media (max-width: 450px) {
                  gap: 4px;
                }
                @media (max-width: 420px) {
                  bottom: 15px;
                }
                h5 {
                  font-weight: 500;
                  @media (max-width: 585px) {
                    font-size: 14px;
                  }
                  @media (max-width: 500px) {
                    width: 200px;
                  }
                  @media (max-width: 420px) {
                    font-size: 12px;
                    width: 150px;
                  }
                }
              }
              .date-content {
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                bottom: 18px;
                right: 15px;
                @media (max-width: 450px) {
                  gap: 4px;
                }
                h5 {
                  font-weight: 500;
                  @media (max-width: 585px) {
                    font-size: 14px;
                  }

                  @media (max-width: 420px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .img-flex-wrapp {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 56px;
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
      h4 {
        letter-spacing: -0.06em;
        font-size: 32px;
        margin-bottom: 20px;
      }
      p {
        font-size: 20px;
        line-height: 32px;
        color: var(--gray);
        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
      h5 {
        text-transform: uppercase;
        font-size: 22px;
        margin-top: 16px;
        color: var(--gray);
        line-height: 32px;
        @media (max-width: 425px) {
          font-size: 18px;
        }
      }
    }
    .content-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 56px;
      @media (max-width: 780px) {
        flex-direction: column;
      }
      .ubu-content-wrapp {
        .title-mobile {
          display: block;
          @media (max-width: 780px) {
            display: none;
          }
        }
      }
    }
  }

  .hero-first-content {
    max-width: 400px;
    width: 100%;
    @media (max-width: 1024px) {
      max-width: 400px;
    }
    @media (max-width: 625px) {
      max-width: 300px;
    }

    .image {
      img {
        max-width: 350px;
        width: 100%;
        @media (max-width: 1024px) {
          max-width: 300px;
        }
        @media (max-width: 625px) {
          max-width: 350px;
        }
      }
    }
    .image-two {
      img {
        max-width: 350px;
        width: 100%;
        @media (max-width: 1024px) {
          max-width: 350px;
        }
        @media (max-width: 625px) {
          max-width: none;
        }
        @media (max-width: 425px) {
          width: 350px;
        }
      }
    }
    .shadow-img {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .flex-container {
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .slide {
      transform: scale(0.2);
      transition: transform 200ms;
      opacity: 0.5;
      img {
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .activeSlide {
      transform: scale(1.1);
      opacity: 1;
    }
  }
}
.mobile-responsive-img {
  @media (max-width: 768px) {
    display: none;
  }
}
.media {
  margin: 32px 0;
  img {
    max-width: 350px;
    width: 100%;
  }
  @media (min-width: 768px) {
    display: none;
  }
}
