.office-assistance-wrapper {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  @media (max-width: 1024px) {
    display: block;
  }
  .bg-img-content {
    position: relative;

    img {
      max-width: 900px;
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .content {
      position: absolute;

      top: 160px;
      left: 64px;
      @media (max-width: 768px) {
        top: 0;
        left: 0;
        margin: 42px 22px;
      }
      h4 {
        font-size: 28px;
        line-height: 44px;
        letter-spacing: -0.06em;
        text-align: left;
        max-width: 444px;
        width: 100%;

        @media (max-width: 425px) {
          font-size: 26px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
  .officeAssistanceImg {
    margin-right: 64px;
    @media (max-width: 1024px) {
      margin-right: 0;
      display: flex;
      justify-content: center;
    }

    img {
      max-width: 435px;
      width: 100%;
      @media (max-width: 768px) {
        margin-top: 120px;
      }
      @media (max-width: 520px) {
        margin-top: 120px;
      }
    }
  }
}
.heading {
  // margin: 24px 0 72px;
  background: #f5f5f5;
  padding: 32px;

  @media (max-width: 520px) {
    margin: 4px 0 0px;
  }
  h4 {
    font-size: 32px;
    letter-spacing: -0.06em;
    text-align: center;
    margin-bottom: 24px;
    @media (max-width: 425px) {
      font-size: 26px;
    }
  }
  button {
    background: var(--yellow);
  }
}
