.files-add-content {
  display: flex;
  align-items: center;
  gap: 9px;
  border: 1px solid #dddddd;
  max-width: 380px;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  input {
    cursor: pointer;
  }
  p {
    line-height: 16px;
  }
}
