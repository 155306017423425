.selectfile-to-details {
  @media (max-width: 992px) {
    display: none;
  }
}
.create-file-wrapper {
  @media (min-width: 992px) {
    display: none;
  }
  .create-file-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    border-bottom: 1px solid #dddddd;
    .create-file {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      p {
        font-size: 18px;
      }
    }
    .menu {
      cursor: pointer;
    }
  }

  .sidebar-backdrop {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .bar {
      position: fixed;
      width: 300px;
      height: 100vh;
      right: 0;
      top: 0px;
      background: #fff;
      backdrop-filter: blur(16px);
      z-index: 1000;
    }
  }
  .header-side-bar {
    padding: 32px;
    position: relative;
    .close-icon {
      cursor: pointer;
      width: 22px;
      position: absolute;
      right: 32px;
      top: 32px;

      img {
        width: 22px;
      }
    }
    .profile-content {
      margin-top: 32px;
      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18px;
          font-weight: 300;
          color: #fff;
          background-color: var(--yellow);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        cursor: pointer;
      }
      h6 {
        margin-top: 16px;
        font-size: 14px;
        text-align: center;
      }
      p {
        font-size: 12px;
        color: var(--primary-gray);
        margin-top: 4px;
        text-align: center;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        padding: 8px 32px;
        width: 100%;
        margin-top: 16px;
        background: #ee5050;
        svg {
          margin-top: 3px;
        }
      }
    }
    .border {
      border: 1px solid #dddddd;
      width: 237px;
      margin-top: 18px;
    }

    .company-drop-down-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      margin-top: 24px;
      margin-bottom: 12px;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .media-building {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        p {
          font-size: 14px;
        }
      }
    }
    .company-name {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      overflow-x: hidden;
      overflow-y: scroll;
      height: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 22px;
        background: #dddddd;
      }

      .link {
        padding: 12px 22px;
        a {
          &:hover {
            color: var(--yellow);
          }
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.files-add-wrapper {
  padding: 24px 120px;
  margin-top: 90px;

  @media (max-width: 1024px) {
    padding: 24px 65px;
  }

  @media (max-width: 992px) {
    margin-top: 0;
  }

  @media (max-width: 525px) {
    padding: 24px 28px;
  }
  h4 {
    font-size: 40px;
    font-family: var(--font-semiBold);
    padding-bottom: 24px;
    &::before {
      content: "";
    }
    &::after {
      content: ".";
      color: var(--yellow);
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 525px) {
      font-size: 26px;
      white-space: nowrap;
    }
  }

  .files-add-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 12px;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
  .files-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin: 32px 0;
    button {
      width: 100%;
      padding: 14px 32px;
      font-size: 14px;
    }
    .cancel-btn {
      button {
        padding: 14px 32px;
      }
    }
  }
  .errormsg {
    p {
      padding-top: 20px;
      color: red;
      font-size: 14px;
    }
  }
}
