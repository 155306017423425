.creating-database-wrapper {
  .creating-database-container {
    background-image: url("../../assets/images/bg-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    .creating-database-content {
      color: #ffffff;
      text-align: center;
      padding: 64px 200px;
      @media (max-width: 768px) {
        padding: 52px;
      }
      @media (max-width: 450px) {
        padding: 52px 24px;
      }
      h3 {
        letter-spacing: -0.06em;
        font-size: 32px;
        font-family: var(--font-semiBold);
        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 32px;
        }
      }
      p {
        margin: 32px 0;
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;
        @media (max-width: 768px) {
          font-size: 18px;
        }
        @media (max-width: 450px) {
          font-size: 16px;
        }
      }
    }
  }
}
