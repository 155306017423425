.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 1000 !important;
  @media (max-width: 450px) {
    padding: 16px;
  }

  .logo {
    img {
      max-width: 352px;
      width: 100%;
      object-fit: contain;
      @media (max-width: 425px) {
        max-width: 160px;
      }
    }
  }
  .login-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .login {
      @media (max-width: 580px) {
        display: none;
      }
    }
  }
}

.terms-heading-wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding: 0 52px 28px;
  margin-bottom: 24px;
  @media (max-width: 580px) {
    padding: 0 22px 18px;
  }
  .terms-close {
    padding: 8px;
    cursor: pointer;
    &:hover {
      border-radius: 2px;
      transition: 0.5s ease-in-out;
    }
  }
  .terms-heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  h4 {
    letter-spacing: -0.06em;
    font-size: 28px;
    font-family: var(--font-semiBold);
    @media (max-width: 580px) {
      font-size: 22px;
    }
  }
}
.terms-scroll-content {
  overflow-y: scroll;
  margin-right: 18px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 6px;
  }
  @media (max-width: 768px) {
    height: 320px;
  }
  @media (max-width: 450px) {
    height: 480px;
  }
  .terms-para {
    padding: 10px 52px;
    @media (max-width: 425px) {
      padding: 0px 30px;
    }
    h5 {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.06em;
      margin-bottom: 16px;
      @media (max-width: 425px) {
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #777777;
      @media (max-width: 425px) {
        font-size: 14px;
      }
    }
    .list {
      margin-top: 12px;
    }
  }
}

.terms-content {
  padding: 10px 50px;

  @media (max-width: 580px) {
    padding: 10px 28px;
  }
  .drop-heading {
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    .drop-img {
      margin-top: -15px;
      img {
        width: 24px;
      }
    }
    h5 {
      letter-spacing: -0.06em;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
  .para-content {
    p {
      font-size: 16px;
      line-height: 28px;
      color: var(--gray);
      margin-left: 40px;
      @media (max-width: 580px) {
        font-size: 14px;
      }
    }
  }
}
.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 52px;
}
