.splashscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 100000;

  .logo {
    position: absolute;
    animation: logo 1.5s infinite;
    animation-delay: 0.5s;
    opacity: 0;
    @media (max-width: 580px) {
      scale: 0.6;
    }
  }
  .circlelogo {
    display: flex;
    position: absolute;
    @media (max-width: 580px) {
      scale: 0.6;
    }
    .dot1 {
      position: relative;
      .circle {
        width: 28px;
        height: 28px;
        border: 2px solid #000;
        border-radius: 30px;
        margin: 6px;
        padding: 4px;
        background: var(--yellow);
        position: relative;
        animation: dots 1.5s infinite;
        animation-delay: 0.5s;
        &::before {
          content: "";
          position: absolute;
          left: 90%;
          top: 0;
          width: 0;
          height: 2px;
          background-color: black;
          transform: rotate(-45deg);
          animation: dotsline 1.5s infinite;
          animation-delay: 0.5s;
        }
      }
    }
    .dot2 {
      position: relative;

      .circle {
        width: 28px;
        height: 28px;
        border: 2px solid #000;
        border-radius: 30px;
        margin: 6px;
        background-color: var(--yellow);
        position: relative;
        animation: dots2 1.5s infinite;
        animation-delay: 0.5s;

        &::before {
          content: "";
          position: absolute;
          left: 90%;
          width: 0;
          right: 0%;
          bottom: 0;
          height: 2px;
          background-color: black;
          transform: rotate(40deg);
          animation: dotsline 1.5s infinite;
          animation-delay: 0.5s;
        }
      }
    }
    .dot3 {
      position: relative;

      .circle {
        width: 28px;
        height: 28px;
        border: 2px solid #000;
        border-radius: 30px;
        margin: 6px;
        background-color: var(--yellow);
        position: relative;
        animation: dots3 1.5s infinite;
        animation-delay: 0.5s;

        &::before {
          content: "";
          position: absolute;
          left: 90%;
          top: 0;
          width: 0;
          height: 2px;
          background-color: black;
          transform: rotate(-45deg);
          animation: dotsline 1.5s infinite;
          animation-delay: 0.5s;
        }
      }
    }
    .dot4 {
      .circle {
        width: 28px;
        height: 28px;
        border: 2px solid #000;
        border-radius: 30px;
        margin: 6px;
        background-color: var(--yellow);
        position: relative;
        animation: dots4 1.5s infinite;
        animation-delay: 0.5s;
      }
    }

    @keyframes logo {
      0% {
        opacity: 0;
        transform: translateX(200px);
      }
      25% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      70% {
        opacity: 0.2;
      }
      80% {
        opacity: 0.7;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes dotsline {
      90% {
        width: 25px;
      }
    }

    @keyframes dots {
      0% {
        right: 0%;
      }
      25% {
        right: 200px;
      }
      50% {
        right: 200px;
      }
      75% {
        right: 200px;
      }

      90% {
        transform: translateY(15px);
      }
      100% {
        transition: 0.2s;
        right: 200px;
      }
    }
    @keyframes dots2 {
      0% {
        right: 0%;
      }
      25% {
        right: 200px;
      }
      50% {
        right: 200px;
      }
      75% {
        right: 200px;
      }

      90% {
        transform: translateY(-15px);
      }
      100% {
        transition: 0.2s;
        right: 200px;
      }
    }
    @keyframes dots3 {
      0% {
        right: 0%;
      }
      25% {
        right: 200px;
      }
      50% {
        right: 200px;
      }
      75% {
        right: 200px;
      }

      90% {
        transform: translateY(15px);
      }
      100% {
        transition: 0.2s;
        right: 200px;
      }
    }
    @keyframes dots4 {
      0% {
        right: 0%;
      }
      25% {
        right: 200px;
      }
      50% {
        right: 200px;
      }
      75% {
        right: 200px;
      }

      90% {
        transform: translateY(-15px);
      }
      100% {
        transition: 0.2s;
        right: 200px;
      }
    }
  }
}
