.document-head {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #dddddd;
  padding: 16px 24px;
  @media (max-width: 992px) {
    display: none;
  }
}

.buy-now-wrapper {
  padding: 24px;
  border-bottom: 1px solid #dddddd;
  p {
    margin-bottom: 8px;
  }
  .buy-now-content-wrapper {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
  .buy-now-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    gap: 16px;

    .card {
      border: 1px solid #dddddd;
      .buy-now-content-file {
        background: #f5f5f5;

        img {
          max-width: 200px;
          width: 100%;
        }
      }
      .buy-now-end-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 12px 16px;
        cursor: pointer;
        .download-icon {
          width: 30px;
          height: 30px;
        }
        .pdf-file {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          .content {
            h6 {
              font-size: 14px;
              margin-bottom: 4px;
            }
            p {
              color: var(--primary-gray);
              font-size: 12px;
            }
          }
        }
        .new {
          display: flex;
          align-items: center;
          gap: 8px;
          h4 {
            font-size: 20px;
            span {
              color: var(--yellow);
            }
          }
        }
      }
      .buy-now-btn {
        margin: 0 12px 12px;

        button {
          width: 100%;
        }
      }

      .empty-bg-clr {
        height: 196px;
        background: #f5f5f5;
        svg {
          width: 70%;
          height: 70%;
        }
      }
    }
  }
}
