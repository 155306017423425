.Post {
  .post-wrapper {
    border: 1px solid black;
    padding: 15px;
    .header-logo {
      img {
        width: 200px;
      }
    }
    h1 {
      text-align: center;
      font-size: 20px;
      padding: 10px;
      font-weight: bold;
    }
    .pdf-content {
      padding: 10px;
    }
    .stamp-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 150px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 100%;
      img {
        width: 150px;
      }
    }
    .header {
      display: flex;
      align-items: center;
      img {
        width: 100px;
      }
      .content {
        h1 {
          text-align: left;
          font-size: 20px;
          padding-bottom: 5px;
          border-bottom: 1px solid gray;
        }
      }
    }
    .second-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: flex-end;
      }
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 12px;
      font-weight: bold;
    }
    p {
      font-size: 10px;
      padding: 3px 0;
    }
  }
}
