.forgot-container {
  background: linear-gradient(90deg, #f5f5f5 0%, #ffffff 100%);
  overflow-y: scroll;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-gray);
    border-radius: 50px;
  }

  .error-window {
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: tomato;
    max-width: 400px;
    width: 100%;
    padding: 12px 32px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    @media (max-width: 768px) {
      top: 180px;
    }
    @media (max-width: 425px) {
      max-width: 270px;
    }
    p {
      color: #fff;
      text-align: center;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: -60px;
    margin-top: 52px;
    img {
      width: 120px;
    }
  }

  .forgot-wrapper {
    padding: 191px 96px;
    max-width: 532px;
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    @media (max-width: 1024px) {
      padding: 56px;
    }

    p {
      color: #9b9899;
      margin-bottom: 32px;
    }

    .forgot-text {
      font-family: var(--font-bold);
      font-size: 40px;
      letter-spacing: -0.06em;
      margin-bottom: 8px;
      color: var(--text-primary);
      white-space: nowrap;
      &::before {
        content: "";
      }
      &::after {
        content: "?";
        color: var(--yellow);
        font-family: var(--font-bold);
      }
    }

    button {
      width: 100%;
      margin-top: 8px;
    }

    .sign-up-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 24px;
      a {
        color: var(--yellow);
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}
