.registration-wrapper {
  padding: 32px 64px;
  @media (max-width: 768px) {
    padding: 28px;
  }
  @media (max-width: 425px) {
    padding: 14px;
  }
  .registration-heading {
    h4 {
      font-size: 32px;
      letter-spacing: -0.06em;
      font-family: var(--font-semiBold);
      @media (max-width: 768px) {
        text-align: center;
      }
      @media (max-width: 425px) {
        font-size: 28px;
      }
    }
  }
  .registration-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 16px;
    margin-top: 42px;
    @media (max-width: 768px) {
      gap: 32px;
    }

    .registration-img {
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        max-width: 330px;
        width: 100%;
        object-fit: cover;
      }
    }
    .content {
      border: 1px solid #dddddd;
      padding: 4px 48px;
      @media (max-width: 768px) {
        padding: 4px 22px;
      }
      li {
        margin: 20px;
        font-size: 18px;
        @media (max-width: 425px) {
          font-size: 16px;
        }
      }
    }
  }
}

.fingertip {
  background: #373435;
  padding: 32px 0;
  @media (max-width: 768px) {
    padding: 32px 22px;
  }
  h3 {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.06em;
    color: #ffffff;
    font-family: var(--font-semiBold);
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
}
